import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar, Button,Modal,notification,Form } from "antd";
import { connect } from "react-redux";
import { BellFilled, LogoutOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import { useKeycloak } from "@react-keycloak/web";
import IntlMessage from "components/util-components/IntlMessage";
import "./MenuContent.scss"
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import ChangePasswordModal from "./ChangePasswordModal";
import { changePasswordAPI } from "api/auth";
import { useIntl } from "react-intl";
import { AllowedAccess } from "react-permission-role";


// const menuItem = [
// 	{
// 		title: "Edit Profile",
// 		icon: EditOutlined ,
// 		path: "/"
//     },

//     {
// 		title: "Account Setting",
// 		icon: SettingOutlined,
// 		path: "/"
//     },
//     {
// 		title: "Billing",
// 		icon: ShopOutlined ,
// 		path: "/"
// 	},
//     {
// 		title: "Help Center",
// 		icon: QuestionCircleOutlined,
// 		path: "/"
// 	}
// ]

export const NavProfile = ({ signOut }) => {
  const [profile, setProfile] = useState({});
  const [keycloak] = useKeycloak();
  const [open,setOpen]=useState(false)
  const intl =useIntl()
  const [form]=Form.useForm()

  useEffect(() => {
    keycloak.loadUserInfo().then((user) => {
      localStorage.setItem("userInfo", JSON.stringify(user));
      setProfile(user);
      // console.log(user);
    });
    keycloak.loadUserProfile().then((profile) => {
      // console.log({ profile });
    });
    // keycloak.
  }, [keycloak]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("idToken");
    // keycloak.logout();
    window.location.href = window._env_.REACT_APP_URL 
      + "/realms/" + window._env_.REACT_APP_REALM 
      + "/protocol/openid-connect/logout?post_logout_redirect_uri=" + window.location.href
      + "&client_id=" + window._env_.REACT_APP_CLIENT
  };

  // const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0">{profile.name}</h4>
            {/* <span className="text-muted">Frontend Developer</span> */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item onClick={()=>setOpen(true)}>
         
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0"><IntlMessage id="topNav.changePassword.text"/></h4>
            {/* <span className="text-muted">Frontend Developer</span> */}
          </div>
        </div>
      
          </Menu.Item>
          <Menu.Divider/>
          {/* <Menu.Item  onClick={() => {keycloak.logout()}}> */}
          <Menu.Item onClick={logout}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal"><IntlMessage id="topNav.changePassword.signOut"/></span>
            </span>
          </Menu.Item> 
        </Menu>
      </div>
    </div>
  );

  const onFinish =(values)=>{
    Modal.confirm({
        title:intl.formatMessage({
          id:"topNav.changePassword.newPassword.confirmation"
        }),
        onOk:async()=>{
          try {
            const response = await changePasswordAPI({
                "old":values?.currentPassword,
                "new":values?.newPassword,
            })
      if (response.data.status === true) {
        notification.info({
          description: response.data.message
        });
            
      }
      if (response.data.status === false) {
        notification.info({
          description: response.data.message
        });
      }
      setOpen(false) 
      form.resetFields()
          } catch (error) {
        notification.error({
          description :error?.data?.message
        })
          }
        }
      })
}

const CustomMenuItem = () => {
  return (
    <Menu.Item key="1">
      Foo
    </Menu.Item>
  );
}

  return (
    <>
      <Dropdown placement="bottomRight" overlay={<></>} trigger={["click"]}>
        <Menu className="d-flex align-item-center" mode="horizontal">
     
          <Menu.Item>
          <AllowedAccess
      roles={["pos.menu.view"]} 
      permissions="" 
      renderAuthFailed={<p></p>}

      >
            <Link  to={{pathname:`${APP_PREFIX_PATH}/pos`,
    }}
            >
    
            <Button className="buttonText" type="default"
             style={{
              borderColor :"#10be4f"
              
               }}
            ><IntlMessage id="sidenav.menu.kb.pos"/></Button> 
           
            </Link>
            </AllowedAccess> 
          </Menu.Item>
          <Menu.Item key={2}>
            <BellFilled />
          </Menu.Item>
        </Menu>
        
      </Dropdown>
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key={3}>
            <span className="mr-3">{profile?.email}</span>
            <Avatar style={{ color: "black" }}>
              {profile?.given_name?.[0]}
              {profile?.family_name?.[0]}
            </Avatar>
          </Menu.Item>
        </Menu>
      </Dropdown>
      <ChangePasswordModal visible={open} onCancel={()=>setOpen(false)} 
      onFinish={onFinish} form={form}
      />
    </>
  );
};

export default connect(null, { signOut })(NavProfile);
