import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Menu from "./Menu";
import Accounts from "./Accounts";
import Transfers from "./Transfers";
import Topups from "./Topups";
import Orders from "./Orders";
import Collection from "./Collection"
import Users from "./Users"
import Statement from "./StatementList";
import Transactions from "./Transactions";
import Settlement from "./Settlement";
import Branches from "./Branches"
import Services from "./Services"
import Groups from "./Groups"
import Customers from "./Customers"
import Applications from "./Applications"
import Circle from "./Circles"
import Bills from "./Bills"
import Billers from "./Billers"
import Approvals from "./Approvals";
import Beneficiaries from "./Beneficiaries";
import TransactionHistory from './TransactionHistory'; 
import Goods from "./Goods";
import Delivery from "./Delivery";
import Fees from "./Fees";


const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  menu: Menu,
  accounts: Accounts,
  transfers: Transfers,
  topups: Topups,
  orders:Orders,
  collection:Collection,
  users:Users,
  statement: Statement,
  transactions:Transactions,
  settlement:Settlement,
  branches:Branches,
  services:Services,
  groups:Groups,
  customers:Customers,
  applications:Applications,
  circle:Circle,
  bills:Bills,
  billers:Billers,
  approvals:Approvals,
  beneficiaries:Beneficiaries,
  transactionHistory:TransactionHistory,
  goods:Goods,
  delivery:Delivery,
  fees:Fees

  
});

export default reducers;
