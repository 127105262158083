import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Kowri';
export const API_BASE_URL = env.API_ENDPOINT_URL

const path = window.location.pathname.split('/')[1];
const domain = window.location.origin;

export const APP_PREFIX_PATH =  (domain.endsWith("otdevhouse.com") || domain.endsWith("getpayjack.com")) ? "/payjack" : (path ? `/${path}` : '/app');
export const AUTH_PREFIX_PATH =  '/auth';

const language = navigator?.language.split(/[-_]/)[0]

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: language,
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: "light"
};

export const setThemeFromLocalStorage = () => {
	const savedTheme = localStorage.getItem('currentTheme');
	
	if (!savedTheme) {
	  THEME_CONFIG.currentTheme = 'light';
	} else {
	  THEME_CONFIG.currentTheme = savedTheme;
	}
  };
  setThemeFromLocalStorage();
  