import { Fees } from "redux/constants/Fees";

const initState = {
  data: [],
  loading: false,
};

const fees = (state = initState, action) => {
  switch (action.type) {
    case Fees.LOADING_FEES_LIST:
      return {
        ...state,
        loading: action.payload,
      };
    case Fees.GET_FEES_LIST:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default fees;