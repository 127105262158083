import {  UserOutlined,SendOutlined,SettingOutlined,BankOutlined,InteractionOutlined, CheckSquareOutlined,HistoryOutlined,
  DashboardOutlined,SolutionOutlined,CreditCardOutlined,ShoppingOutlined,CarOutlined,ContactsOutlined,ReconciliationOutlined,
  MoneyCollectOutlined
} from "@ant-design/icons";

import { APP_PREFIX_PATH } from "configs/AppConfig";
import { kbHome } from "constants/ApiConstant";

export const menu = [
  {
    key: 0,
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: `sidenav.dashboard`,
    name: "Dashboard",
    icon: <DashboardOutlined />,
    // icon: <UserOutlined />,
    // icon: 'accounts.svg',
    breadcrump: false,
    roles: [ "dashboard.menu.view"],
    submenu: []
  },
  {
    key: 6,
    path: `${APP_PREFIX_PATH}/disbursements`,
    title: `sidenav.menu.disbursement`,
    name: "Disbursements",
    icon: <SendOutlined />,
    breadcrump: false,
    roles: [ "disbursement.menu.view"],
    submenu: [],
    children:[
      {
        key: 7,
        path: `${APP_PREFIX_PATH}/transfers`,
        title: `sidenav.menu.transfers`,
        name: "Transfers",
        // icon: <SwapOutlined/>,
        breadcrump: false,
        roles: [ "transfer.menu.view"],
        submenu: [],
      },
      {
    key: 8,
    path: `${APP_PREFIX_PATH}/topups`,
    title: `sidenav.menu.topups`,
    name: "Topups",
    // icon: <BankOutlined />,
    breadcrump: false,
    roles: [ "topup.menu.view"],
    submenu: [],
  },
 
    ]
  },
  {
    key: 20,
    path: `${APP_PREFIX_PATH}/bill-payments`,
    title: `sidenav.menu.billPayments`,
    name: "Bill Payments",
    icon:<CreditCardOutlined />,
    breadcrump: false,
    roles: [ "billPayments.menu.view"],
    submenu: [],
  },
  {
    key: 1,
    path: `${APP_PREFIX_PATH}/history`,
    title: `sidenav.menu.kb.history`,
    name: "History",
    icon: <HistoryOutlined />,
    breadcrump: false,
    roles: [ "history.menu.view"],
    submenu: [],
    children:[
      {
        key: 2,
        path: `${APP_PREFIX_PATH}/history/:1`,
        title:`sidenav.menu.kb.order.history`,
        name: "Order History",
        // icon: <TransactionOutlined />,
        breadcrump: false,
        roles: [ "order-history.menu.view"],
      },
      {
        key: 3,
        path: `${APP_PREFIX_PATH}/history/:2`,
        title:  `sidenav.menu.kb.transaction.history`,
        name: "Transaction History",
        // icon: <TransactionOutlined />,
        breadcrump: false,
        roles: [ "transaction-history.menu.view"],
      },
      {
      key: 4,
      path: `${APP_PREFIX_PATH}/history/:3`,
      title: `sidenav.menu.kb.history.settlement`,
      name: "Settlement",
      // icon: <FileOutlined />,
      breadcrump: false,
      roles: [ "settlement.menu.view"],
    },
    {
      key: 5,
      path: `${APP_PREFIX_PATH}/history/reports`,
      title: `sidenav.menu.kb.reports`,
      name: "Reports",
      // icon: <FileOutlined />,
      breadcrump: false,
      roles: [ "reports.menu.view"],
      submenu: [],
    }
  ]
  },
  // {
  //   key: 5,
  //   path: `${APP_PREFIX_PATH}/pos`,
  //   title: `sidenav.menu.kb.pos`,
  //   name: "PointOfSale",
  //   icon: <SelectOutlined />,
  //   breadcrump: false,
  //   submenu: [],
  // },

  {
    key: 9,
    path: `${APP_PREFIX_PATH}/crm`,
    title: `sidenav.menu.crm`,
    name: "CRM",
    icon: <InteractionOutlined />,
    breadcrump: false,
    roles: [ "crm.menu.view"],
    submenu: [],
    children:[
      {
        key: 15,
        path: `${APP_PREFIX_PATH}/bill-management`,
        title: `sidenav.menu.kb.bills-management`,
        name: "Bill Management",
        breadcrump: false,
        roles: [ "bills.menu.view"],
        submenu: [],
      },
      {
        key: 17,
        path: `${APP_PREFIX_PATH}/circles`,
        title: `sidenav.menu.kb.circles`,
        name: "Circles",
        breadcrump: false,
        roles: [ "circles.menu.view"],
        submenu: [],
      },
      {
        key: 10,
        path: `${APP_PREFIX_PATH}/customer-management`,
        title: `sidenav.menu.kb.customer-management`,
        name: "Customers",
        // icon: <TeamOutlined />,
        breadcrump: false,
        roles: [ "customer.menu.view"],
        submenu: [],
      },
    
     
    ]
  },
 
  {
    key: 12,
    path: `${APP_PREFIX_PATH}/settings`,
    title: `sidenav.menu.kb.settings`,
    name: "Settings",
    icon: <SettingOutlined />,
    breadcrump: false,
    roles: [ "settings.menu.view"],
    submenu: [],
    children:[
      {
        key: 16,
        path: `${APP_PREFIX_PATH}/applications`,
        title: `sidenav.menu.kb.applications`,
        name: "Applications",
        // icon: <FontColorsOutlined />,
        breadcrump: false,
        roles: [ "applications.menu.view"],
        submenu: [],
      },
      {
        key: 15,
        path: `${APP_PREFIX_PATH}/branch-management`,
        title: `sidenav.menu.kb.branch-management`,
        name: "BranchManagement",
        // icon: <BranchesOutlined />,
        breadcrump: false,
        roles: [ "branches.menu.view"],
        submenu: [],
      },
      {
        key: 13,
        path: `${APP_PREFIX_PATH}/business-profile`,
        title: `sidenav.menu.kb.business-profile`,
        name: "Business Profile",
        // icon: <PicCenterOutlined />,
        breadcrump: false,
        roles: [ "profile.menu.view"],
        submenu: [],
      },
      {
        key: 11,
        path: `${APP_PREFIX_PATH}/collections`,
        title: `sidenav.menu.kb.collections`,
        name: "Collections",
        // icon: <RadiusSettingOutlined />,
        breadcrump: false,
        roles: [ "collections.menu.view"],
        submenu: [],
      },
      {
        key: 14,
        path: `${APP_PREFIX_PATH}/user-management`,
        title: `sidenav.menu.kb.user-management`,
        name: "Users & Permissions",
        // icon: <UserOutlined />,
        breadcrump: false,
        roles: [ "user.menu.view"],
        submenu: [],
      },
      
     

    ]
  },
  {
    key: 22,
    path: `${APP_PREFIX_PATH}/merchant-management`,
    title: `sidenav.menu.kb.merchant-management`,
    name: "BillerManagement",
    icon: <BankOutlined />,
    breadcrump: false,
    roles: [ "merchants.menu.view"],
    submenu: [],
  },
  {
    key: 23,
    path: `${APP_PREFIX_PATH}/account-management`,
    title: `sidenav.menu.kb.account-management`,
    name: "AccountManagement",
    icon: <UserOutlined />,
    breadcrump: false,
    roles: [ "account.menu.view"],
    submenu: [],
  },
  {
    key: 8,
    path: `${APP_PREFIX_PATH}/approvals`,
    title: `sidenav.menu.kb.approval-request`,
    name: "ApprovalRequest",
    icon: <CheckSquareOutlined />,
    breadcrump: false,
    roles: [ "approvals.menu.view"],
    submenu: [],
  },
  {
    key: 2,
    path: `${APP_PREFIX_PATH}/beneficiary-management`,
    title: `sidenav.menu.kb.beneficiary-management`,
    name: "BeneficiaryManagement",
    icon: <ContactsOutlined />,
    breadcrump: false,
    roles: [ "beneficiaries.menu.view"],
    submenu: [],
  },
  {
    key: 3,
    path: `${APP_PREFIX_PATH}/transfer-preference`,
    title: `sidenav.menu.kb.transfer-preference`,
    name: "TransferPreference",
    icon: <ReconciliationOutlined />,
    breadcrump: false,
    roles: [ "transferPreference.menu.view"],
    submenu: [],
  },
  {
    key: 25,
    path: `${APP_PREFIX_PATH}/goods-management`,
    title: `sidenav.menu.kb.goods-management`,
    name: "GoodsManagement",
    icon: <ShoppingOutlined />,
    breadcrump: false,
    roles: [ "goodsManagement.menu.view"],
    submenu: [],
  },
  {
    key: 26,
    path: `${APP_PREFIX_PATH}/delivery-management`,
    title: `sidenav.menu.kb.delivery-management`,
    name: "DeliveryManagement",
    icon: <CarOutlined />,
    breadcrump: false,
    roles: [ "deliveryManagement.menu.view"],
    submenu: [],
  },
  {
    key: 27,
    path: `${APP_PREFIX_PATH}/fee-management`,
    title: `sidenav.menu.kb.fee-management`,
    name: "FeeManagement",
    icon: <MoneyCollectOutlined />,
    breadcrump: false,
    roles: [ "fee-management.menu.view"],
    submenu: [],
  }
]; 
